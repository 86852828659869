function IconInbound() {
  return (
    <svg
      className="icon icon-tabler icons-tabler-outline icon-tabler-inbound"
      fill="none"
      height={24}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <path d="M3 12a9 9 0 1 0 18 0 9 9 0 1 0-18 0M15 9l-6 6" />
      <path d="M13 15H9v-4" />
    </svg>
  )
}
export default IconInbound
